import { UrlQueryParameters } from "../config/urlQueryParameters";

const PAGES_NUMBER = "?size=9";

export const ApiRoutes = {
  RECORD_CHECK: "/record-check",
  RECORD_CHECK_ENCRYPTED_VINREG: "/record-check/anonymized",
  RECORD_CHECK_ENGINE_DATA: "/record-check/engine-data",
  RECORD_EXISTS: "/record-exists",
  PAYMENT_START: "/payment/start",
  PAYMENT_START_AUTHENTICATED: "/payment/auth/start",
  PAYMENT_METHODS: "/payment/methods",
  PAYMENT_PAY: "/payment/pay",
  PAYMENT_DETAILS: "/payment/details",
  PAYMENT_AUTOMATIC_REFUND: "payment/refund",
  PAYMENT_REDEEM_CREDITS: "/payment/auth/credits",
  PAYMENT_FULLY_DISCOUNTED: "/payment/discounted/pay",
  VOUCHER_CODE: "payment/vouchers",
  CHECK_VOUCHER_CODE: "/voucher/details",
  VHR_TRANSACTION: "/vhr",
  VHR_REPORT: "/vhr/credits",
  VINREG_BY_TRANSACTION: "/vhr/vinreg/transaction",
  VINREG_BY_REPORT_ID: "/vhr/vinreg/credits",
  VHR_FIND: "/vhr/find",
  LOGIN: "/account/login",
  LOGIN_SOCIAL_PROVIDER: "/account/login/social-provider",
  LOGOUT: "/account/logout",
  SIGN_UP: "/account/register",
  BUSINESS_SIGN_UP: "/account/b2b/register",
  PASSWORD_RESET: "/account/password-reset",
  SET_PASSWORD: "/account/password-change",
  PASSWORD_UPDATE: "/account/auth/password-update",
  PASSWORD_CHANGE_TOKEN_VALIDATION: "/account/password-change-token-validation",
  EMAIL_UPDATE: "/account/auth/email-change",
  USER_DASHBOARD: `/user/dashboard/vhrs${PAGES_NUMBER}`,
  USER_DASHBOARD_CREDITS: "/user/dashboard/credits",
  USER_REMAINING_CREDITS: "/user/remaining-credits",
  PACKAGES: "/packages",
  BUSINESS_PACKAGES: "/packages/business",
  NET_AMOUNT: "/net-amount",
  VIES_CHECK: "/vies",
  INVOICES_USERS: "/invoices/users",

  setPassword: (token: string) => `${ApiRoutes.SET_PASSWORD}?${UrlQueryParameters.SET_PASSWORD_TOKEN}=${token}`,
  checkPasswordChangeTokenValidation: (token: string) =>
    `${ApiRoutes.PASSWORD_CHANGE_TOKEN_VALIDATION}?${UrlQueryParameters.SET_PASSWORD_TOKEN}=${token}`,
  recordExists(vin: string) {
    return `${ApiRoutes.RECORD_EXISTS}/${vin}`;
  },
  recordCheckForTransaction(vin: string) {
    return `${ApiRoutes.RECORD_CHECK}/${vin}`;
  },
  recordCheckEngineData(vin: string) {
    return `${ApiRoutes.RECORD_CHECK_ENGINE_DATA}/${vin}`;
  },
  recordCheckdForTransactionForEncryptedVinreg(vin: string) {
    return `${ApiRoutes.RECORD_CHECK_ENCRYPTED_VINREG}/${vin}`;
  },
  paymentMethods(transactionId: string) {
    return `${ApiRoutes.PAYMENT_METHODS}/${transactionId}`;
  },
  startTransaction(recordCheckId: string) {
    return `${ApiRoutes.PAYMENT_START}/${recordCheckId}`;
  },
  startPackageTransaction(recordCheckId: string, packageName: string) {
    return `${ApiRoutes.PAYMENT_START_AUTHENTICATED}/${recordCheckId}/${packageName}`;
  },
  startPackageTransactionWithoutVin(packageName: string, lang: string) {
    return `${ApiRoutes.PAYMENT_START_AUTHENTICATED}/${packageName}?${UrlQueryParameters.LANGUAGE}=${lang}`;
  },
  pay(transactionId: string) {
    return `${ApiRoutes.PAYMENT_PAY}/${transactionId}`;
  },
  paymentDetails(transactionId: string) {
    return `${ApiRoutes.PAYMENT_DETAILS}/${transactionId}`;
  },
  automaticTransactionRefund(transactionId: string) {
    return `${ApiRoutes.PAYMENT_AUTOMATIC_REFUND}/${transactionId}`;
  },
  vhrTransactionUrl(transactionId, lang) {
    return `${ApiRoutes.VHR_TRANSACTION}/${transactionId}?${UrlQueryParameters.LANGUAGE}=${lang}`;
  },
  vhrReportUrl(reportId, lang) {
    return `${ApiRoutes.VHR_REPORT}/${reportId}?${UrlQueryParameters.LANGUAGE}=${lang}`;
  },
  getVinregByTransactionUrl(transactionId) {
    return `${ApiRoutes.VINREG_BY_TRANSACTION}/${transactionId}`;
  },
  getVinregByReportId(reportId) {
    return `${ApiRoutes.VINREG_BY_REPORT_ID}/${reportId}`;
  },
  findVhr(vinreg, lang) {
    return `${ApiRoutes.VHR_FIND}/${vinreg}/${lang}`;
  },
  redeemCredits(recordCheckId: string) {
    return `${ApiRoutes.PAYMENT_REDEEM_CREDITS}/${recordCheckId}`;
  },
  packagesUrl(lang) {
    return `${ApiRoutes.PACKAGES}?${UrlQueryParameters.LANGUAGE}=${lang}`;
  },
  businessPackagesUrl(lang) {
    return `${ApiRoutes.BUSINESS_PACKAGES}?${UrlQueryParameters.LANGUAGE}=${lang}`;
  },
  partnerPackagesUrl(lang, partner) {
    return `/packages/partner/${partner}?${UrlQueryParameters.LANGUAGE}=${lang}`;
  },
  basicPackagesUrl(lang, packageName) {
    return `/packages/basic/${packageName}?${UrlQueryParameters.LANGUAGE}=${lang}`;
  },
  voucherCode(transactionId: string) {
    return `${ApiRoutes.VOUCHER_CODE}/${transactionId}`;
  },
  checkVoucherCode(voucherCode) {
    return `${ApiRoutes.CHECK_VOUCHER_CODE}/${voucherCode}`;
  },
  checkVoucherCodeWithPackageName(voucherCode, packageName) {
    return `${ApiRoutes.CHECK_VOUCHER_CODE}/${voucherCode}?${UrlQueryParameters.PACKAGE_NAME}=${packageName}`;
  },
  viesCheck(vatNumber: string) {
    return `${ApiRoutes.VIES_CHECK}/${vatNumber}`;
  },
  netAmount(transactionId: string, country: string, viesResult: boolean) {
    return `${ApiRoutes.NET_AMOUNT}/${transactionId}?${UrlQueryParameters.COUNTRY}=${country}&${UrlQueryParameters.VIES_RESULT}=${viesResult}`;
  },
  customerNetAmount(transactionId: string, country: string) {
    return `${ApiRoutes.NET_AMOUNT}/${transactionId}?${UrlQueryParameters.COUNTRY}=${country}`;
  },
  fullyDiscountedTransactionPayment(transactionId: string) {
    return `${ApiRoutes.PAYMENT_FULLY_DISCOUNTED}/${transactionId}`;
  },
  getInvoiceUser(uuid: string) {
    return `${ApiRoutes.INVOICES_USERS}?${UrlQueryParameters.UUID}=${uuid}`;
  },
};

export const getRoutesWithVin = () => {
  return [ApiRoutes.RECORD_EXISTS, ApiRoutes.RECORD_CHECK, ApiRoutes.VHR_FIND];
};
