import { action, observable, computed, autorun, makeObservable } from "mobx";
import ReportPricing from "./reportPricing.store";
import { LocalStorage } from "utils/localstorage";
import { calculateTotalRecordAmount } from "utils/pricing-response/calculateTotalRecordAmount";
import { calculateRecords } from "utils/pricing-response/calculateRecords";
import { reportCombination } from "utils/pricing-response/reportCombination";

interface Records {
  local: number;
  eu: number;
  us: number;
}
interface DetailedRecordsPerCountry {
  recordsPerCountry: {
    [key: string]: number;
  };
  externalReports: {
    [key: string]: object;
  };
}

interface RecordDetails {
  odometerReadingsCount: number;
  inspectionRecordsCount: number;
  serviceAndRepairRecordsCount: number;
  externalReportUS: number;
}

const defaultRecords = {
  local: 0,
  eu: 0,
  us: 0,
};

const defaultRecordDetails = {
  odometerReadingsCount: 0,
  inspectionRecordsCount: 0,
  serviceAndRepairRecordsCount: 0,
  externalReportUS: 0,
};

class RecordCheckStore {
  private key = "recordCheck_store_local";
  private localStorage: LocalStorage;

  @observable
  public uuid: string;

  @observable
  public query: string;

  @observable
  public encryptedQuery: string;

  @observable
  public isEncrypted: boolean = false;

  @observable
  public makeAndModel: string;

  @observable
  public presentCountry: string = "";

  @observable
  public reportDataTotal: number = 0;

  @observable
  public recordsPerCountry: Records = defaultRecords;

  @observable
  public reportCombination: string;

  @observable
  public recordDetails: RecordDetails = defaultRecordDetails;

  @observable
  public enginePower: string;

  @observable
  public co2Emission: string;

  @observable
  public make: string;

  @observable
  public model: string;

  @observable
  public fuelType: string;

  @observable
  public isReactivated: string = "no";

  @observable
  public detailedRecordsPerCountry: DetailedRecordsPerCountry = {
    recordsPerCountry: {},
    externalReports: {},
  };

  constructor() {
    makeObservable(this);
    this.localStorage = new LocalStorage(this.key);

    this.loadDataFromLocalStorage();

    autorun(() => {
      this.localStorage.saveDataToLocalStorage(this.toJSON);
    });
  }

  @computed
  get toObject() {
    return {
      uuid: this.uuid,
      query: this.query,
      makeAndModel: this.makeAndModel,
      presentCountry: this.presentCountry,
      reportDataTotal: this.reportDataTotal,
      recordsPerCountry: this.recordsPerCountry,
      detailedRecordsPerCountry: this.detailedRecordsPerCountry,
      reportCombination: this.reportCombination,
      recordDetails: this.recordDetails,
      encryptedQuery: this.encryptedQuery,
      isEncrypted: this.isEncrypted,
      enginePower: this.enginePower,
      co2Emission: this.co2Emission,
      make: this.make,
      model: this.model,
      fuelType: this.fuelType,
      isReactivated: this.isReactivated,
    };
  }

  @computed
  get toJSON() {
    return JSON.stringify(this.toObject);
  }

  @action
  setAsReactivated() {
    this.isReactivated = "yes";
  }

  @action
  public setDataFromApi(data) {
    const { recordCheck, uuid, encryptedQuery } = data;

    this.uuid = uuid;
    this.query = recordCheck.query;
    this.encryptedQuery = encryptedQuery;
    this.isEncrypted = !!encryptedQuery;
    this.makeAndModel = recordCheck.makeAndModel;
    this.presentCountry = recordCheck.presentCountry;
    this.reportDataTotal = calculateTotalRecordAmount(data);
    this.recordsPerCountry = calculateRecords(data);
    this.detailedRecordsPerCountry = {
      recordsPerCountry: recordCheck.recordsPerCountry,
      externalReports: recordCheck.externalReports,
    };
    this.reportCombination = reportCombination.getReportCombination(data);
    this.make = recordCheck.make;
    this.model = recordCheck.model;
    this.enginePower = recordCheck.enginePowerPS;
    this.co2Emission = recordCheck.co2EmissionsMixedDriving;
    this.fuelType = recordCheck.fuelType;
    this.recordDetails = {
      odometerReadingsCount: recordCheck?.odometerReadingsCount || 0,
      inspectionRecordsCount: recordCheck?.inspectionRecordsCount || 0,
      serviceAndRepairRecordsCount: recordCheck?.serviceAndRepairRecordsCount || 0,
      externalReportUS: recordCheck?.externalReports?.us?.displayRecords || 0,
    };
    this.isReactivated = "no";
  }

  @action
  private loadDataFromLocalStorage() {
    const data = this.localStorage.loadDataFromLocalStorage<this>();
    if (!data) {
      return null;
    }

    this.uuid = data.uuid;
    this.query = data.query;
    this.encryptedQuery = data.encryptedQuery;
    this.isEncrypted = data.isEncrypted;
    this.makeAndModel = data.makeAndModel;
    this.presentCountry = data.presentCountry;
    this.reportDataTotal = data.reportDataTotal;
    this.recordsPerCountry = data.recordsPerCountry;
    this.detailedRecordsPerCountry = data.detailedRecordsPerCountry;
    this.reportCombination = data.reportCombination;
    this.recordDetails = data.recordDetails;
    this.make = data.make;
    this.model = data.model;
    this.enginePower = data.enginePower;
    this.co2Emission = data.co2Emission;
    this.fuelType = data.fuelType;
    this.isReactivated = data.isReactivated;
  }

  @computed
  get trackingDataObject() {
    return {
      singleReportPrice: ReportPricing.totalAmount.toString(),
      reportDataTotal: this.reportDataTotal.toString(),
      usData: this.recordsPerCountry.us.toString(),
      euData: this.recordsPerCountry.eu.toString(),
      localData: this.recordsPerCountry.local.toString(),
      reportCountry: this.presentCountry?.toUpperCase(),
      reportCombination: this.reportCombination,
      makeModel: this.makeAndModel,
      vin: this.isEncrypted ? this.encryptedQuery : this.query,
      vinHashed: this.isEncrypted,
    };
  }

  @computed
  get trackingData() {
    return {
      reportDataTotal: this.reportDataTotal,
      vinreg: this.isEncrypted ? this.encryptedQuery : this.query,
      reportCombination: this.reportCombination,
      isReactivated: this.isReactivated,
    };
  }
}

export default new RecordCheckStore();
export { RecordCheckStore };
